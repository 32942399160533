#root{
  font-family: 'Prompt', sans-serif;
}
.MuiDrawer-paper{
  overflow-y: hidden !important;
}

.setGemi{
  display: initial;
}
@media only screen and (max-width: 600px) {
  .setGemi {
    display: none;
  }
}